import React from 'react'
import './components.css'
import { Link } from 'react-router-dom'

export default function Navbar() {
    //the navbar
    return (
            <div className="mainContainer">
                <Link className="navItem" to="/">
                    Sign up
                </Link>
                <Link className="navItem" to='/privacy'>
                    Privacy Policy
                </Link>
                <Link className="navItem" to='/terms'>
                    Terms of Service
                </Link>
            </div>
    )
}