import './components.css';
import '../App.css';
import Email from './email';
import Navbar from './navbar';
import Footer from './footer';

function Splash() {
  //the main page
  return (
      <div className='container'>
        <Navbar />
        <div className='imgCont'>
          <img className='splash' src='splash.png' alt='splash' />
        </div>
        <Email />
        <Footer />
      </div>
  );
}

export default Splash;