import React from 'react'
import './components.css'
import { Link } from 'react-router-dom'

export default function Footer() {

    return (
            <div className="bottomContainer">
                    <Link className="footLogo" to="https://www.instagram.com/paperback.app/?hl=en">
                        <img className='splash' src='insta.png' alt='instagram' />
                    </Link>
                    <Link className="footLogo" to='https://twitter.com/paperback_app_'>
                        <img className='splash' src='twitter.png' alt='twitter' />
                    </Link>
                    <Link className="footLogo" to='mailto:app.paperback@gmail.com'>
                        <img className='splash' src='email.png' alt='email' />
                    </Link>
            </div>
    )
}