import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Splash from './components/splash';
import Privacy from './components/privacy';
import Terms from './components/terms';
import Reroute from './components/reroute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Splash />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/reroute' element={<Reroute />} />
      </Routes>
    </Router>
  );
}

export default App;
