import './components.css';
import Navbar from './navbar';

function Terms() {
  return (
    <div className='container'>
      <Navbar />
      <div className='privacy'>
      <body>

<h1>Terms of Service</h1>

<p><strong>Paperback is currently only available to users located in the United States. Shared Secrets is available globally. Known in this agreement collectively as "the apps".</strong></p>

<p>These Terms of Service (“Terms”) govern your use of and access to our services, including our websites, APIs, data storage, application, ads, commerce services, and affiliated services (collectively known as “Services”) and any information, text, image, link, graphic, audio, video, or other material or arrangements of material uploaded, downloaded, or appearing on the Services. By using the Services you agree to be bound by these terms.</p>

<p>These terms are an agreement between you and Teejeigh llc., which provides Paperback, Shared Secrets, and the Services, 370 Jay St. Floor 7, New York, NY, United States of America. The words “we”, “us”, and “our” mean Teejeigh LLC.</p>

<h2>1. Who May Use the Services</h2>
<p>By utilizing the Services, you are agreeing to establish a legally binding agreement with us and confirming your eligibility to access these services according to the laws of your relevant jurisdiction. You must be at least 18 years old to use the Services. If you are agreeing to these Terms and utilizing the Services on behalf of a company, organization, government entity, or any legal entity, you affirm that you have the necessary authorization to do so and possess the power to legally commit such an entity to these Terms. In such cases, the terms "you" and "your" within these Terms shall pertain to the said entity.</p>

<h2>2. Privacy</h2>
<p>Our handling of the information you provide when using our Services is outlined in our <a href="https://www.thepbapp.com/privacy">Privacy Policy</a>. By utilizing the Services, you acknowledge that your use signifies your agreement to the collection and utilization (in accordance with the Privacy Policy) of this information, which may include the transfer of such information within the United States and/or to other countries for storage, processing, and utilization by us and our affiliated entities.</p>
<h2>3. Content on the Services</h2>
<p>You are accountable for your utilization of the Services and for any Content you contribute, ensuring adherence to applicable laws, rules, and regulations. It is recommended that you only share Content that you are comfortable making public.</p>

<p>Any reliance on or use of Content or materials posted through the Services, or obtained by you through the Services, is at your own discretion. We do not endorse, back, represent, or guarantee the comprehensiveness, accuracy, truthfulness, or reliability of any Content or communications shared via the Services, nor do we endorse the viewpoints expressed through the Services. Understand that using the Services may expose you to Content that could be offensive, detrimental, erroneous, or otherwise unsuitable, or even to postings that have been mislabeled or are misleading. The originator of the Content holds exclusive responsibility for it. We may not oversee or manage the Content posted through the Services, and we disclaim responsibility for such Content.</p>

<p>We maintain the right to delete Content that breaches the User Agreement, including instances of copyright or trademark infringement, intellectual property misuse, impersonation, illegal conduct, or harassment.</p>

<p>In the event you believe that your Content has been replicated in a manner constituting copyright/trademark infringement, please file a report using our email in the format provided below. Please note that by providing a report you are agreeing to the following terms:</p>

<ul>
  <li>You are the copyright owner or an authorized representative of the copyright owner</li>
  <li>We may provide third parties with details of your report</li>
  <li>You declare under penalty of perjury that all of the information provided is accurate and that you are authorized to act as the copyright/trademark owner or as an authorized representative of the owner.</li>
  <li>You acknowledge that filing a report will initiate a DMCA complaint and a statutorily-defined legal process through which your full complaint and contact information are provided to the violator.</li>
  <li>512(f) Acknowledgment: I understand that under 17 U.S.C. § 512(f), I may be liable for any damages, including costs and attorneys' fees, if I knowingly materially misrepresent that reported material or activity is infringing.</li>
</ul>

<p>If you are an authorized agent of the owner, you have a good faith belief that use of the material in the manner complained of is not authorized by the owner, its agent, or the law</p>
<p><strong>Owner`s full name<br />
Your full name if not the owner<br />
Company<br />
Job title<br />
Your email<br />
Street address<br />
City<br />
State<br />
Postal Code<br />
Country<br />
Phone number<br />
Description of the infringement as presented in Paperback or Shared Secrets<br />
Title(s) included in the infringement<br />
Description of the original work<br />
Title of the original work<br />
Link to original work</strong></p>

<p>You may also mail your complaint to:</p>

<p>Teejeigh llc<br />
340 W 42nd #2175<br />
New York, NY 10036</p>
<h2>4. Your Rights and Grant of Right in the Content</h2>

<p>You maintain ownership of any Content you submit, post, or display on or through the Services. Your Content remains yours, including any audio, photos, and videos that are integrated into it.</p>

<p>Content that you share is divided into two categories: free-for-all and paid.</p>

<h3>4a. Free-for-all:</h3>
<p>By sharing, posting, or displaying Content on or through the Services, you provide us with a global, irrevocable, non-exclusive, royalty-free license (with the option to sublicense) to utilize, duplicate, reproduce, process, adapt, modify, publish, transmit, showcase, and distribute the said Content across all existing or future media or methods of distribution, whether currently known or developed later (to clarify, these rights encompass actions like curating, transforming, and translating). This license empowers us to make your Content accessible worldwide. You consent to us using this license to offer, promote, and enhance the Services, as well as to permit us to provide rights to other entities or individuals to access, share, promote, or publish the Content submitted through the Services on different platforms or services. This usage of your Content, both by us and by others, does not involve any compensation to you, as the use of the Services by you is deemed satisfactory compensation for the Content and the rights granted. This license does not include content that you have put forth to other users for monetary compensation. Such instances of a commercial exchange are covered by a separate agreement between you and Teejeigh LLC.</p>

<p>We have established an evolving set of guidelines for how our ecosystem partners can engage with your Content on the Services. These guidelines are intended to uphold an open ecosystem while respecting your rights. You understand that we might alter or modify your Content as it is distributed, published, broadcasted, or shared by us and our partners, adapting it for various media formats and potential censorship in the case of user defined filtering.</p>

<p>You declare and guarantee that you possess, or have acquired, all necessary rights, licenses, permissions, consents, authority, and power to provide the rights described herein for any Content you share, post, or display on or through the Services. You affirm that such Content will not incorporate material subject to copyright or other proprietary rights unless you possess the required authorization or are lawfully authorized to post the material and grant us the aforementioned license.</p>

<h3>4b. Paid:</h3>
<p>You retain ownership of any Content you submit, post, or display on or through the Services. Your Content remains yours, including any audio, photos, and videos that are integrated into it. This section covers only the portion of your content that becomes available upon the successful completion of a monetary transaction. All information provided as part of the content as offered to other users as a sample, query, example, or otherwise offered without charge is covered by the Free-for-all 4a section above.</p>

<p>By sharing, posting, or displaying Content on or through the Services, you provide us with a global, non-exclusive, royalty-free license (with the option to sublicense) to utilize, duplicate, reproduce, process, adapt, modify, publish, transmit, showcase, and distribute the said Content across all existing or future media or methods of distribution, whether currently known or developed later (to clarify, these rights encompass actions like curating, transforming, and translating). This license empowers us to make your Content accessible worldwide. In scenarios where you provide content to other users for a fee, you grant us the rights necessary to provide, promote, and improve the Services, while also permitting other entities or individuals to access, purchase, and enjoy the Content you've shared. This usage of your Content, both by us and by others, involves compensation to you as outlined in your author agreement.</p>

<p>We have established an evolving set of guidelines for how our ecosystem partners can engage with your Content on the Services. These guidelines are intended to uphold an open ecosystem while respecting your rights. You understand that we might alter or modify your Content as it is distributed, published, broadcasted, or shared by us and our partners, adapting it for various media formats and potential censorship in the case of user defined filtering.</p>

<p>You declare and guarantee that you possess, or have acquired, all necessary rights, licenses, permissions, consents, authority, and power to provide the rights described herein for any Content you share, post, or display on or through the Services. You affirm that such Content will not incorporate material subject to copyright or other proprietary rights unless you possess the required authorization or are lawfully authorized to post the material and grant us the aforementioned license.</p>    
<h2>5. Using the Services</h2>

<p>Our Services are subject to constant evolution. As a result, the Services may undergo changes periodically at our discretion. We hold the right to cease providing the Services, either permanently or temporarily, or to exclude certain features within the Services, either to you or to users in general. Furthermore, we retain the authority to establish limitations on usage and storage, exercised at our sole discretion, and at any given time. We reserve the prerogative to eliminate or decline the distribution of any Content on the Services, control the distribution or visibility of any Content on the platform, suspend or terminate user accounts, and reclaim usernames without incurring any liability to you.</p>

<p>In exchange for granting you access to and usage of the Services, you acknowledge that we, along with our third-party providers and partners, may display advertisements on the Services or in conjunction with the display of Content or information from the Services, regardless of whether submitted by you or others. You also commit to using our Services responsibly, which involves refraining from misuse or interference with the Services or their access via methods other than the prescribed interface and instructions provided by us. You agree not to circumvent any technical limitations in the software furnished as part of the Services, or to engage in reverse engineering, decompiling, or disassembling the software, except as explicitly permitted by applicable law. While using the Services, you shall not partake in activities such as:</p>

<ol type="i">
    <li>accessing non-public sections of the Services, our computer systems, or the technical delivery systems of our providers;</li>
    <li>probing, scanning, or testing system vulnerabilities or attempting to breach or bypass security or authentication measures;</li>
    <li>accessing or searching the Services in any manner other than through our current interfaces, except when allowed in a separate agreement with us;</li>
    <li>altering email or posting headers or using the Services to transmit altered, deceptive, or false source-identifying information; or</li>
    <li>disrupting or interfering with the access of other users, hosts, or networks, which includes sending viruses, overloading, flooding, spamming, or scripting actions that burden the Services.</li>
</ol>

<p>We retain the right to access, read, retain, and disclose information as deemed necessary, including cases where it's essential to:</p>

<ol type="i">
    <li>comply with applicable laws, regulations, legal processes, or government requests;</li>
    <li>enforce the Terms, including investigations into potential violations;</li>
    <li>detect, prevent, or address fraud, security, or technical issues;</li>
    <li>respond to user support inquiries; or</li>
    <li>safeguard the rights, property, or safety of paperback, Shared Secrets, its users, and the public.</li>
</ol>

<p>We do not disclose personally identifiable information to third parties, except as outlined in our Privacy Policy.</p>

<p>Specific services or features on the apps may come with their own separate terms and conditions, which will apply when you use those services. By utilizing these additional services, you agree to the corresponding terms, which become part of our agreement with you. In the event of any inconsistency between the applicable additional terms and these Terms, the additional terms shall take precedence during your use of those specific services.</p>

<h3>Your Account</h3>

<p>Creating an account might be necessary for accessing some of our Services. It's your responsibility to safeguard your account by using a strong password and restricting its use solely to your account. We cannot and will not be held liable for any loss or damage arising from your failure to comply with these security measures.</p>

<p>Most communications from the Services can be managed by you. However, certain communications, like service announcements and administrative messages, are essential and cannot be opted out of. If you add your phone number to your account and later change or deactivate the number, it's your responsibility.</p>
<h2>6. Disclaimers and Limitations of Liability</h2>

<h3>The Services are Provided "As-Is"</h3>

<p>Your usage and access of the Services, as well as any Content, are undertaken at your own risk. You acknowledge and accept that the Services are presented to you in their current state, "AS IS," and "AS AVAILABLE." The term "Paperback Entities" and "Shared Secrets Entities" refers to Teejeigh llc, including its parent companies, affiliates, related entities, officers, directors, staff, agents, representatives, partners, and licensors. Without constraining the foregoing, to the maximum extent allowed by applicable law, THE PAPERBACK AND SHARED SECRETS ENTITIES DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, INCLUDING MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. The Paperback and Shared Secrets Entities provide no warranty or representation and renounce all responsibility and liability for:</p>

<ol type="i">
    <li>the comprehensiveness, accuracy, accessibility, timeliness, security, or reliability of the Services or any Content;</li>
    <li>any harm to your computer system, data loss, or other damage arising from your usage of or access to the Services or any Content;</li>
    <li>the removal of, or failure to store or transmit, any Content or other communications maintained by the Services; and</li>
    <li>whether the Services will fulfill your requirements or remain available on an uninterrupted, secure, or error-free basis.</li>
</ol>

<p>No guidance or information, whether oral or written, obtained from the Paperback and Shared Secrets Entities or via the Services, will create any warranty or representation not explicitly outlined here.</p>

<h3>Liability Limitation</h3>

<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE PAPERBACK AND SHARED SECRETS ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER DIRECTLY OR INDIRECTLY INCURRED, OR ANY LOSS OF DATA, UTILIZATION, GOODWILL, OR OTHER INTANGIBLE LOSSES, ARISING FROM:</p>

<ol type="i">
    <li>YOUR ACCESS TO OR USAGE OF, OR INABILITY TO ACCESS OR USE, THE SERVICES;</li>
    <li>ANY ACTIONS OR CONTENT OF THIRD PARTIES ON THE SERVICES, INCLUDING BUT NOT LIMITED TO DEFAMATORY, OFFENSIVE, OR ILLEGAL ACTIONS BY OTHER USERS OR THIRD PARTIES;</li>
    <li>ANY CONTENT ACQUIRED THROUGH THE SERVICES; OR</li>
    <li>UNAUTHORIZED ACCESS, USAGE, OR MODIFICATION OF YOUR TRANSMISSIONS OR CONTENT.</li>
</ol>

<p>UNDER NO CIRCUMSTANCES SHALL THE COLLECTIVE LIABILITY OF THE PAPERBACK AND SHARED SECRETS ENTITIES EXCEED THE GREATER OF ONE HUNDRED U.S. DOLLARS ($100.00) OR THE AMOUNT YOU PAID US, IF ANY, IN THE SIX MONTHS PRIOR TO THE CLAIM, FOR THE SERVICES THAT GAVE RISE TO THE CLAIM. THE LIMITATIONS SPECIFIED IN THIS SECTION APPLY TO ANY FORM OF LIABILITY, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, AND APPLY WHETHER OR NOT THE PAPERBACK AND SHARED SECRETS ENTITIES WERE AWARE OF THE POSSIBILITY OF SUCH DAMAGE. THIS HOLDS TRUE EVEN IF A REMEDY OUTLINED HEREIN IS DETERMINED TO HAVE FAILED IN ACHIEVING ITS PRIMARY OBJECTIVE.</p>
<h2>7. General</h2>

<p>We may periodically update these Terms. Any modifications will not apply retroactively, and the most recent version of the Terms, always accessible at <a href="https://thepbapp.com/terms" target="_blank" rel="noreferrer noopener">thepbapp.com/terms</a>, will govern our relationship with you. We strive to notify you of significant revisions, such as through service notifications or emails sent to the address linked with your account. By continuing to utilize or access the Services after the implementation of such revisions, you consent to be bound by the updated Terms.</p>

<h3>Governing Law and Jurisdiction</h3>

<p>The laws of the State of Michigan, excluding its conflict of law principles, will preside over these Terms and any disputes arising between you and us. Any conflicts linked to these Terms or the Services will be exclusively heard in the federal or state courts located in Michigan, United States. In this context, you agree to personal jurisdiction and waive any objections regarding inconvenient forum.</p>

<h3>For U.S. Government Entities</h3>

<p>Should you be a federal, state, or local government entity within the United States using the Services officially and unable to accept the aforementioned clauses on controlling law, jurisdiction, or venue due to legal constraints, these clauses do not apply to you. In such cases, these Terms and any associated actions will be governed by United States of America law (without considering conflict of laws) and, in the absence of federal law and to the extent allowed by it, the laws of the State of Michigan (excluding choice of law).</p>

<h3>Severability and Waiver</h3>

<p>In the event that any provision of these Terms is deemed invalid or unenforceable, it will be adjusted or removed to the extent required, while the remaining provisions of these Terms will persist in full force and effect. Our decision not to enforce any right or provision of these Terms will not be interpreted as a waiver of that right or provision.</p>

<h3>Contact Us</h3>

<p>For any inquiries regarding these Terms, please feel free to reach out to: <a href="mailto:app.paperback@gmail.com">app.paperback@gmail.com</a>.</p>

<p><strong>Effective Date: August 19, 2023</strong></p>
      </ body>
      </div>
    </div>
  );
}

export default Terms;