import './components.css';
import Navbar from './navbar';

function Privacy() {
  return (
    <div className='container'>
        <Navbar />
    <div className="privacy">
    <body>
    <h1>We're Trying to Keep Our Privacy Policy as Short as Possible</h1>
    <p>However, our regulators require us to describe our practices in detail. Here are the main parts:</p>

    <h2>Paperback and Shared Secrets are Public Platforms</h2>
    <h2>Paperback and Shared Secrets are referred to as "Teejeigh LLC apps" in this document.</h2>
    <p>Learn about what you are sharing:</p>
    <ul>
        <li>When you use Teejeigh LLC apps, your profile is public.</li>
    </ul>
    <h3>Private:</h3>
    <ul>
        <li>Your account will not appear in the search function for user accounts.</li>
        <li>Only users you follow will be able to follow you back.</li>
    </ul>
    <h3>Public:</h3>
    <ul>
        <li>Your account will appear in searches, discovery feeds, and other aggregations of user discovery that promote
            the books, clubs, and other activities for which you are a part.</li>
        <li>Users will be able to follow you, view the books you like, your bookshelf, book clubs you are a part of,
            your name, your unique name, the amount of followers you have, the amount of accounts you are following,
            your account picture, and your badge level.</li>
        <li>Your activity in private clubs is only viewable to members of that club.</li>
        <li>Your activity in public clubs is viewable to all members and non-members.</li>
    </ul>
    <h3>Both:</h3>
    <p>You will appear as a member of book clubs you join, and members that belong to book clubs of which you are also
        a member will be able to see your activity within the book club and messaging board. If the book club to which
        you are a member is public, your activity within the club will be public. If the club is private, club activity
        is only viewable for club members.</p>

    <h2>We Collect Some Data About You</h2>
    <p>Learn what we collect:</p>
    <ul>
        <li>Information that you provide:</li>
    </ul>
    <p>When you sign up for Teejeigh LLC apps, Gmail provides us with the email address and name you provided to them. Review
        Alphabet's privacy policy <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener noreferrer">here</a>. We also collect information you provide to us when you fill out your profile including: your name, your unique name, your “bio”, and your picture. Additionally, if you message us privately, we store the content of the message.</p>
    <p>Through your use of the app, we collect: books you like, download, comment on, and share.</p>
    <ul>
        <li>Information provided passively:</li>
    </ul>
    <p>As you use Teejeigh LLC apps, we store information about your use to include books you like, download, comment on, are presented with, and share. We also store your interactions in book clubs to include sharing and commenting.</p>
    <ul>
        <li>Purchases and payments:</li>
    </ul>
    <p>When you make purchases and payments through the app, we collect information from the transaction in order to provide a safe and reliable payment experience and to ensure the service or product you paid for is provided.</p>

    <h2>We Use Your Data to Improve the Platform</h2>
    <p>Learn how we use your data:</p>
    <p>To improve your Teejeigh LLC apps experience and that of all our users, we use your data to refine our recommendation engine. Books you interact with will be used to make further recommendations to you, your followers, and other users.</p>
    <p>If you are a public user, we present your unique name to other users during search and discovery. We regularly use the information provided from users to ensure a safe and secure user experience for all users, including authenticating your account, defending against fraud, preventing unauthorized use, and illegal activity.</p>
    <p>We often use aggregated user data to ensure our services are performing adequately and to make adjustments to the user experience. Additionally, we use the data to test prospective features, conduct research, surveys, and for troubleshooting.</p>
    <p>We may use the information you provide to us to communicate with you about your experience on Teejeigh LLC apps in order to make improvements.</p>
    <p>We use may use ads to be able to keep Teejeigh LLC apps free for everyone. In serving you ads, we DO NOT use interest-based ads or share your information with our advertisers to provide targeted ads.</p>
    <p>Your information is kept for the life of your account, unless you delete your account. However, it's important to remember your public content, including: likes, comments, bookshelf, book club activity can exist elsewhere on the web by search engines and other sharing platforms.</p>
    <p>If your account is suspended, we may keep part or all of your information indefinitely to prevent repeat account creation.</p>

    <h2>Use Our Settings Menu to Control Your Experience</h2>
    <p>Learn how to adjust your settings:</p>
    <p>You can change your privacy settings at any time by opening the settings menu in your profile on the Teejeigh LLC apps.</p>
    <p>You can change the information you have provided to us at any time by using the “edit profile” option in your profile page.</p>
    <p>You can delete your account and the information associated with it by using the delete account options at the bottom of the settings menu. Your account information will be permanently deleted 30 days after the request is submitted. To reinstate your account, simply login with your email address associated with the account. Generally, your account data is kept as long as your profile remains active.</p>

    <h2>Who Is Teejeigh LLC apps For?</h2>
    <p>We do not censor the books or posts you are provided with; therefore, Teejeigh LLC apps may not be appropriate for people under the age of 18 years old.</p>
    <p>As it currently stands, Teejeigh LLC apps do not have appropriate censorship options for users. All books provided are uncensored and may contain language some users find offensive. Due to this, we don't recommend Teejeigh LLC apps for people under the age of 18. Additionally, by using Teejeigh LLC apps, you must be legally old enough to enter into and consent to an agreement with Teejeigh LLC apps to allow us to process your user data.</p>

    <h2>Changes to Our Privacy Policy</h2>
    <p>If anything changes, we'll let you know:</p>
    <p>This is the most current version of our privacy policy. If we make any changes, we'll let you know and give you the chance to review them. Our latest privacy policy can always be found on our site: <a href="https://thepbapp.com" target="_blank" rel="noopener noreferrer">thepbapp.com</a>.</p>

    <h2>Let Us Know If You Have Any Questions!</h2>
    <p>Contact us with any concerns:</p>
    <p>We want to hear from you! If you have any thoughts, criticisms, would like to request a copy of your data, or questions about our privacy policy, feel free to contact us by email <a href="mailto:app.paperback@gmail.com">app.paperback@gmail.com</a>.</p>
    </body>
    </div>
    </div>
  );
}

export default Privacy;