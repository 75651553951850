import React, { useState } from 'react'
import './components.css'
import { sendInput } from './queries';

export default function Email() {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [valid, setValid] = useState(false);
    console.log(submitted)

    const handleInput = (event) => {
        setEmail(event.target.value);
        setValid(isEmail(event.target.value))
    }

    function isEmail(email) {
        const eRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return eRegex.test(email);
    }

    const handleSubmit = () => {
        sendInput(email);
        setSubmitted(true);
        setEmail('');
    }

    const resetEmail = () => {
        setSubmitted(false);
    }

    return (
            <div className="altContainer">
                {submitted ? <div className='mainContainer'><div className='input'>Thanks for joining!</div>
                            <br />
                            <button className='submitButton' type='submit' onClick={resetEmail} >Submit another email?</button>
                            </div>
                            :
                <form>
                    <div className='input' >Enter your email to join our demo and stay updated! </div>
                    <br />
                    <div className='mainContainer'>
                    <input className='emailBox' placeholder='example@email.com'
                        maxLength={50} required={true} type='text'
                        id='email' name='email' onChange={handleInput}/>
                    <button className={valid?'submitButton':'badButton'} type='submit' onClick={valid?handleSubmit:''} >{valid?'Submit':'...'}</button>
                    </div>
                </form>}
            </div>
    )
}