import { useEffect } from 'react'

const Reroute = () => {
    //push the user to the respective app store
    useEffect(() => {
        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            window.location.href = 'https://apps.apple.com/app/paperback/id6467305662'
        }else if(/Macintosh|Mac OS X/i.test(navigator.userAgent)){
            window.location.href = 'https://apps.apple.com/app/paperback/id6467305662'
        }else{
            window.location.href = 'https://play.google.com/store/apps/details?id=com.orionone'
        }
    },[])

    return null
}

export default Reroute;